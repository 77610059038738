import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const claimBaseInformationsService = {
  update(claimId, claim_base_information) {
    return fetch("/api/claims/".concat(claimId, "/claim_base_informations/").concat(claim_base_information.id), {
      ...request.put,
      body: JSON.stringify({
        claim_base_information
      })
    });
  },
  create(claimId, claim_base_information) {
    return fetch("/api/claims/".concat(claimId, "/claim_base_informations"), {
      ...request.post,
      body: JSON.stringify({
        claim_base_information
      })
    });
  }
};
export default claimBaseInformationsService;