export const employeeActivityTableHeaders = claim => {
  const headersLeft = [{
    text: 'Name',
    sortable: true,
    filterableByText: true,
    valueFnForSortAndFilter: r => r.data.claimEmployee.name || ''
  }, {
    text: 'Role',
    sortable: true,
    filterableByText: true,
    valueFnForSortAndFilter: r => r.data.claimEmployee.role || ''
  }, {
    text: 'State',
    sortable: true,
    valueFnForSortAndFilter: r => r.data.claimEmployee.state || ''
  }];
  const headersPerProject = claim.claim_group.project_methodology === 'project_basis' ? claim.claim_group.claim_project_reports.map((_, index) => {
    return [{
      text: 'R&D%',
      sortable: true,
      valueFnForSortAndFilter: r => r.data.apportionment.rnd_apportionment || ''
    }, {
      text: 'Months',
      sortable: true,
      valueFnForSortAndFilter: r => r.data.apportionment.rnd_amount || ''
    }];
  }).flatMap(x => x) : [];
  const headersRight = [{
    text: 'R&D%',
    sortable: true,
    valueFnForSortAndFilter: r => r.data.claimEmployee.rnd_apportionment || ''
  }, {
    text: 'Wages',
    sortable: true,
    valueFnForSortAndFilter: r => r.data.claimEmployee.wage || ''
  }, {
    text: 'R&D Amount',
    sortable: true,
    valueFnForSortAndFilter: r => r.data.claimEmployee.rnd_amount || ''
  }];
  return [...headersLeft, ...headersPerProject, ...headersRight];
};