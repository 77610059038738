import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const claimGrossReceiptsService = {
  all(claimId) {
    return fetch("/api/claims/".concat(claimId, "/claim_gross_receipts"), request.get);
  },
  delete(claim_gross_receipt) {
    return fetch("/api/claims/".concat(claim_gross_receipt.claim_id, "/claim_gross_receipts/").concat(claim_gross_receipt.id), request.delete);
  },
  update(claim_gross_receipt) {
    return fetch("/api/claims/".concat(claim_gross_receipt.claim_id, "/claim_gross_receipts/").concat(claim_gross_receipt.id), {
      ...request.put,
      body: JSON.stringify({
        claim_gross_receipt
      })
    });
  },
  create(claim_gross_receipt) {
    return fetch("/api/claims/".concat(claim_gross_receipt.claim_id, "/claim_gross_receipts"), {
      ...request.post,
      body: JSON.stringify({
        claim_gross_receipt
      })
    });
  }
};
export default claimGrossReceiptsService;