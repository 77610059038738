export const reportTemplateInitial = {
  id: 0,
  name: '',
  report_template_categories: [{
    title: 'Disclosure Notice',
    content: 'text here...',
    exclude_from_report: false,
    should_apply_styles: true
  }, {
    title: 'Certification',
    content: 'text here...',
    exclude_from_report: false,
    should_apply_styles: true
  }, {
    title: 'Option Letter',
    content: 'text here...',
    exclude_from_report: false,
    should_apply_styles: true
  }, {
    title: 'Report Sign Off',
    content: 'text here...',
    exclude_from_report: false,
    should_apply_styles: true
  }, {
    title: 'Executive Summary',
    content: 'text here...',
    exclude_from_report: false,
    should_apply_styles: true
  }, {
    title: 'Ayming',
    content: 'text here...',
    exclude_from_report: false,
    should_apply_styles: true
  }, {
    title: 'The Ayming US Methodology',
    content: 'text here...',
    exclude_from_report: false,
    should_apply_styles: true
  }, {
    title: 'Qualification of Research Expenses',
    content: 'text here...',
    exclude_from_report: false,
    should_apply_styles: true
  }, {
    title: 'Credit Calculation',
    content: 'text here...',
    exclude_from_report: false,
    should_apply_styles: true
  }, {
    title: 'Project List',
    content: 'text here...',
    exclude_from_report: false,
    should_apply_styles: true
  }, {
    title: 'Contemporaneous Documentation',
    content: 'text here...',
    exclude_from_report: false,
    should_apply_styles: true
  }, {
    title: 'Project Team Qualifications',
    content: 'text here...',
    exclude_from_report: false,
    should_apply_styles: true
  }, {
    title: 'Financial Analysis',
    content: 'text here...',
    exclude_from_report: false,
    should_apply_styles: true
  }, {
    title: 'Eligible R&D Projects',
    content: 'text here...',
    exclude_from_report: false,
    should_apply_styles: true
  }, {
    title: 'Employee Activities',
    content: 'text here...',
    exclude_from_report: false,
    should_apply_styles: true
  }]
};