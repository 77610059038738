export const wageTableHeaders = showTypeAndYear => {
  return [{
    text: 'Employee',
    sortable: true,
    filterableByText: true,
    valueFnForSortAndFilter: r => (r.data.claimEmployee.name || '') + ' ' + (r.data.claimEmployee.role || '')
  }, {
    text: 'State',
    sortable: true,
    filterableByText: true,
    valueFnForSortAndFilter: r => r.data.claimEmployee.state || ''
  }, showTypeAndYear ? {
    text: 'Type',
    sortable: true,
    valueFnForSortAndFilter: r => r.data.claimEmployee.claim.id_in_provider_system ? 'Claim' : 'Base'
  } : null, showTypeAndYear ? {
    text: 'Year',
    sortable: true,
    valueFnForSortAndFilter: r => Number(r.data.claimEmployee.claim.label)
  } : null, {
    text: 'Wages ($)',
    sortable: true,
    valueFnForSortAndFilter: r => Number(r.data.claimEmployee.wage)
  }, {
    text: 'R&D %',
    sortable: true,
    valueFnForSortAndFilter: r => Number(r.data.claimEmployee.rnd_apportionment)
  }, {
    text: 'R&D Amount',
    sortable: true,
    valueFnForSortAndFilter: r => Number(r.data.claimEmployee.rnd_amount)
  }].filter(h => !(h === null));
};