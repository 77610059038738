import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const claimSuppliesService = {
  all(claimId) {
    return fetch("/api/claims/".concat(claimId, "/claim_supplies"), request.get);
  },
  delete(claim_supply) {
    return fetch("/api/claims/".concat(claim_supply.claim_id, "/claim_supplies/").concat(claim_supply.id), request.delete);
  },
  update(claim_supply) {
    return fetch("/api/claims/".concat(claim_supply.claim_id, "/claim_supplies/").concat(claim_supply.id), {
      ...request.put,
      body: JSON.stringify({
        claim_supply
      })
    });
  },
  create(claim_supply) {
    return fetch("/api/claims/".concat(claim_supply.claim_id, "/claim_supplies"), {
      ...request.post,
      body: JSON.stringify({
        claim_supply
      })
    });
  }
};
export default claimSuppliesService;