export const rentalLeaseComputerTableHeaders = showTypeAndYear => {
  return [{
    text: 'Vendor Name',
    sortable: true,
    filterableByText: true,
    valueFnForSortAndFilter: r => r.data.object.vendor_name || ''
  }, {
    text: 'State',
    sortable: true,
    filterableByText: true,
    valueFnForSortAndFilter: r => r.data.object.state
  }, showTypeAndYear ? {
    text: 'Type',
    sortable: true,
    valueFnForSortAndFilter: r => r.data.object.claim.id_in_provider_system ? 'Claim' : 'Base'
  } : null, showTypeAndYear ? {
    text: 'Year',
    sortable: true,
    valueFnForSortAndFilter: r => Number(r.data.object.claim.label)
  } : null, {
    text: 'Expences',
    sortable: true,
    valueFnForSortAndFilter: r => Number(r.data.object.expense)
  }, {
    text: 'R&D %',
    sortable: true,
    valueFnForSortAndFilter: r => Number(r.data.object.rnd_apportionment)
  }, {
    text: 'QREs',
    sortable: true,
    valueFnForSortAndFilter: r => Number(r.data.object.qre)
  }, {
    text: '',
    sortable: false
  }].filter(h => !(h === null));
};