import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const claimSubcontractorsService = {
  all(claimId) {
    return fetch("/api/claims/".concat(claimId, "/claim_contractors"), request.get);
  },
  delete(claim_contractor) {
    return fetch("/api/claims/".concat(claim_contractor.claim_id, "/claim_contractors/").concat(claim_contractor.id), request.delete);
  },
  update(claim_contractor) {
    return fetch("/api/claims/".concat(claim_contractor.claim_id, "/claim_contractors/").concat(claim_contractor.id), {
      ...request.put,
      body: JSON.stringify({
        claim_contractor
      })
    });
  },
  create(claim_contractor) {
    return fetch("/api/claims/".concat(claim_contractor.claim_id, "/claim_contractors"), {
      ...request.post,
      body: JSON.stringify({
        claim_contractor
      })
    });
  }
};
export default claimSubcontractorsService;