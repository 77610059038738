import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import request from '@rd-web-markets/shared/dist/services/request';
const claimEmployeeDocumentService = {
  create: (employee_id, formData) => {
    return fetch("/api/claim_employees/".concat(employee_id, "/claim_employee_documents"), {
      ...request.postFormData,
      body: formData
    });
  },
  delete: (employee_id, document_id) => {
    return fetch("/api/claim_employees/".concat(employee_id, "/claim_employee_documents/").concat(document_id), request.delete);
  }
};
export default claimEmployeeDocumentService;