export const fetchData = (claimId, dataSetMethod, dataService, dispatch, handleError) => {
  async function getData() {
    try {
      if (claimId) {
        dataSetMethod(await dataService.all(claimId));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  }
  getData();
};
export const handleDeleteFn = async (claimId, deleteObject, dataSetMethod, dataObjects, dataService, refreshClaimMethod, dispatch, handleError) => {
  if (deleteObject.id) {
    try {
      await dataService.delete(deleteObject);
      const objects = await dataService.all(claimId);
      dataSetMethod(objects);
      refreshClaimMethod();
    } catch (error) {
      dispatch(handleError(error));
    }
  } else {
    const newObjects = [...dataObjects.filter(o => o.id !== deleteObject.id)];
    dataSetMethod(newObjects);
  }
};
export const handleAddFn = async (dataSetMethod, dataObjects, object) => {
  dataSetMethod([...dataObjects, object]);
};
const updateObjects = (object, dataObjects, dataSetMethod) => {
  const newObjects = [...dataObjects];
  const objectIndex = newObjects.findIndex(o => o.id === object.id);
  newObjects[objectIndex] = {
    ...object
  };
  dataSetMethod([...newObjects]);
};
export const handleChangeFn = (event, object, dataObjects, dataSetMethod) => {
  const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
  object[event.target.name] = value;
  updateObjects(object, dataObjects, dataSetMethod);
};
export const saveChangeFn = (object, dataSetMethod, dataObjects, dataService, refreshClaimMethod, dispatch, handleError) => {
  let method;
  if (object.id) {
    method = 'update';
  } else {
    method = 'create';
  }
  dataService[method](object).then(updatedObject => {
    if (method === 'create') {
      dataObjects[dataObjects.length - 1].id = updatedObject.id;
    }
    updatedObject.hasError = false;
    updateObjects(updatedObject, dataObjects, dataSetMethod);
    refreshClaimMethod();
  }).catch(e => {
    if (method === 'create') {
      object.id = "new-rlc-".concat(Math.random());
      dataObjects[dataObjects.length - 1].id = object.id;
    }
    object.hasError = true;
    updateObjects(object, dataObjects, dataSetMethod);
    dispatch(handleError(e));
  });
};

// export const handleImportFn = async (claimId, dataSetMethod, dataObjects, dataService, data) => {
//   for (const line of data.split('\n')) {
//     if (line.length === 0) {
//       continue;
//     }
//     const lineSplit = line.split('\t');
//     const newObject = await dataService.create({
//       claim_id: claimId,
//       name: lineSplit[0],
//       net_amount: (lineSplit[1] || '').replace(',', '')
//     });
//     dataSetMethod([...dataObjects, newObject]);
//   }
// }