import { handleError } from "../store/features/alertSlice";
import { useDispatch } from "react-redux";
export const useDeleteConfirmation = (confirmationText, deleteCallback) => {
  const dispatch = useDispatch();
  return async () => {
    if (window.confirm(confirmationText)) {
      try {
        await deleteCallback();
      } catch (error) {
        dispatch(handleError(error));
      }
    }
  };
};